import { getBrandByDomainName } from "./BrandHelper";

const brand = getBrandByDomainName();

export const getTenantOptions = () => {
  switch (brand) {
    case "ahs":
      return [{ value: 2, label: "AHS" }];

    case "hsa":
      return [{ value: 4, label: "HSA" }];
      
    case "ahs-proconnect":
      return [{ value: 8, label: "AHS ProConnect" }];
  
    case "hsa-proconnect":
      return [{ value: 9, label: "HSA ProConnect" }];
  
    default:
      return [
        { value: 7, label: "Frontdoor"},
        { value: 2, label: "AHS" },
        { value: 4, label: "HSA" },
        { value: 8, label: "AHS ProConnect" },
        { value: 9, label: "HSA ProConnect" },
        { value: 5, label: "Oneguard" },
        { value: 6, label: "Landmark" },
        { value: 10, label: "FTDR" },
      ];
  }
};

import React, { useEffect } from "react";
import { getBrandByDomainName } from "../../helpers/BrandHelper";
import { getDocumentTitle } from "../../helpers/MetaData";
import MyHeader from "../Header/Header";

const Dashboard = () => {
  useEffect(() => {
    document.title = `Dashboard | ${getDocumentTitle()}`;
  }, []);

  return (
    <>
      <MyHeader brand={getBrandByDomainName()} />
      {/* <div>
      <iframe
        src="https://frontdoor.looker.com/embed/dashboards/421?allow_login_screen=true"
        title="Looker Dashboard"
        style={{
          width: "90%",
          height: "75vh",
          margin: "2em auto",
          border: "1px solid #dcdcdc",
          resize: "vertical",
        }}
      ></iframe>
    </div> */}
      <p className="text-center mt-12">
        401 - You are not authorised to view this page.
      </p>
    </>
  );
};

export default Dashboard;

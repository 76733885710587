import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "@ftdr/blueprint-core/themes/ahs-web-app.css";
import { Buffer } from 'buffer';
import {
  Input,
  Button,
  Message,
  IconWarningTriangleFull,
  IconProhibitionFull,
  IconLockFull,
  Notification,
  IconEyeEmpty,
  IconEyeOff,
  ProgressIndicator,
  // Link,
} from "@ftdr/blueprint-components-react";
import protobuf from "protobufjs";
import MyHeader from "../Header/Header";
import {
  LINK_EXPIRED_TEXT,
  MAX_OTP_LENGTH,
  ERROR_TEXT,
  INPUT_HINT,
  HYDRATE_SHORTURL_REQUEST_URL,
  LINK_EXPIRED_CODE,
  ERROR_CODE,
  NUMBERS_REGEX,
  GENERAL_ERROR_MSG,
  INVALID_QUERY_PARAMS_ERROR,
  GET_TENANTID_REQUEST_URL,
} from "../../Constants";
import Footer from "../Footer/Footer";
import {
  getBrandByDomainName,
  getBrandNameByTenantID,
} from "../../helpers/BrandHelper";
import { GetGuestToken } from "@ftdr/crypto-js";
import "./OTP.css";
import { getDocumentTitle } from "../../helpers/MetaData";

const OTP = () => {
  const maxLength = MAX_OTP_LENGTH;
  const [text, setText] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [requestErrorText, setRequestErrorText] = useState("");
  const [linkExpired, setLinkExpired] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [shortlink, setShortLink] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [queryParams, setQueryParams] = useState("");

  const [tenantID, setTenantID] = useState();
  const [brand, setBrand] = useState("");
  const [isProtected, setIsProtected] = useState(true);
  const shortCode = useParams("code");
  let Content;

  useEffect(() => {
    setShortLink(`${window.location.origin}/` + shortCode.code);
    setShortUrl(window.location.href);
    setQueryParams(
      window.location.search.replace("?", "").replaceAll("?", "&")
    );
  }, [shortCode.code]);

  /********************************* Get Access Token ************************************/
  useEffect(() => {
    setPageLoading(true);

    GetGuestToken({
      handler: (token) => {
        setAccessToken(token);
      },
    });
  }, []);

  /********************************* Get tenantID ************************************** */
  useEffect(() => {
    if (accessToken && !tenantID) {
      let buffer;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-protobuf",
      };
      protobuf.load("protos/shortlink.proto").then(async function (root) {
        var FetchTenantIDRequest = root.lookupType(
          "shortlinkpb.FetchTenantIDRequest"
        );
        var FetchTenantIDResponse = root.lookupType(
          "shortlinkpb.FetchTenantIDResponse"
        );
        var payload = {
          shortURL: shortlink,
          queryParams: queryParams,
        };

        var errMsg = FetchTenantIDRequest.verify(payload);

        if (errMsg) setRequestErrorText("422 - Invalid payload.");

        var message = FetchTenantIDRequest.create(payload);

        buffer = FetchTenantIDRequest.encode(message).finish();
        console.log(buffer)

        const encodedDetails = Buffer.from(buffer).toString("base64");

        const encodedQuery = encodedDetails.replaceAll("=", "");

        try {
          const res = await fetch(
            `${GET_TENANTID_REQUEST_URL}${encodedQuery}`,
            {
              headers,
            }
          );
          const resp = await res.arrayBuffer();
          const int8ar = new Uint8Array(resp);

          const decodedRes = await FetchTenantIDResponse.decode(int8ar);

          if (decodedRes && decodedRes.errors.length > 0) {
            if (
              decodedRes.errors[0].code === LINK_EXPIRED_CODE ||
              decodedRes.errors[0].code === INVALID_QUERY_PARAMS_ERROR
            ) {
              setPageLoading(false);
              setLinkExpired(true);
              setBrand(getBrandByDomainName());
            }
          } else {
            const response = decodedRes.tenantID;
            setBrand(getBrandNameByTenantID(response));
            setTenantID(response);
            setIsProtected(decodedRes.isProtected);
            setPageLoading(false);
          }
        } catch (err) {
          setPageLoading(false);
          setRequestErrorText(GENERAL_ERROR_MSG);
          setBrand(getBrandByDomainName());
          console.error(err);
        }
      });
    }
  }, [accessToken, tenantID, isProtected, shortlink, queryParams]);

  useEffect(() => {
    if (!isProtected && accessToken && tenantID && !linkExpired) {
      setPageLoading(true);
      let buffer;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-protobuf",
      };
      protobuf.load("protos/shortlink.proto").then(async function (root) {
        var HydrateShortURLRequest = root.lookupType(
          "shortlinkpb.HydrateShortURLRequest"
        );
        var HydrateShortURLResponse = root.lookupType(
          "shortlinkpb.HydrateShortURLResponse"
        );
        var payload = {
          tenantID: tenantID,
          shortURL: shortlink,
          code: "",
          queryParams: queryParams,
        };

        var errMsg = HydrateShortURLRequest.verify(payload);

        if (errMsg) setRequestErrorText("422 - Invalid payload.");

        var message = HydrateShortURLRequest.create(payload);

        buffer = HydrateShortURLRequest.encode(message).finish();

        const encodedDetails = Buffer.from(buffer).toString("base64");

        const encodedQuery = encodedDetails.replaceAll("=", "");

        try {
          const res = await fetch(
            `${HYDRATE_SHORTURL_REQUEST_URL}${encodedQuery}`,
            { headers }
          );
          const resp = await res.arrayBuffer();
          const int8ar = new Uint8Array(resp);

          const decodedRes = await HydrateShortURLResponse.decode(int8ar);
          setPageLoading(false);

          if (decodedRes.longURL) {
            setRedirecting(true);
            window.location.href = decodedRes.longURL;
            setRedirecting(false);
          } else {
            if (
              decodedRes.errors[0].code === LINK_EXPIRED_CODE ||
              decodedRes.errors[0].code === INVALID_QUERY_PARAMS_ERROR
            ) {
              setPageLoading(false);
              setLinkExpired(true);
            }
          }
        } catch (err) {
          setPageLoading(false);
          setRequestErrorText(GENERAL_ERROR_MSG);
          console.error(err);
        }
      });
    }
  }, [accessToken, shortlink, isProtected, queryParams, tenantID, linkExpired]);

  /*********************************** Submit OTP handler *************************************** */

  const submitOTPHandler = (e) => {
    e.preventDefault();
    if (errorText) setErrorText("");
    setLoading(true);

    //**protobuf code here***
    let buffer;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-protobuf",
    };

    protobuf.load("protos/shortlink.proto").then(async function (root) {
      var HydrateShortURLRequest = root.lookupType(
        "shortlinkpb.HydrateShortURLRequest"
      );
      var HydrateShortURLResponse = root.lookupType(
        "shortlinkpb.HydrateShortURLResponse"
      );

      var payload = {
        tenantID: tenantID,
        shortURL: shortlink,
        code: text,
        queryParams: queryParams,
      };

      var errMsg = HydrateShortURLRequest.verify(payload);

      if (errMsg) setErrorText(errMsg);

      var message = HydrateShortURLRequest.create(payload);

      buffer = HydrateShortURLRequest.encode(message).finish();

      const encodedDetails = Buffer.from(buffer).toString("base64");

      const encodedQuery = encodedDetails.replaceAll("=", "");

      try {
        const res = await fetch(
          `${HYDRATE_SHORTURL_REQUEST_URL}${encodedQuery}`,
          { headers }
        );
        const resp = await res.arrayBuffer();
        const int8ar = new Uint8Array(resp);

        const decodedRes = await HydrateShortURLResponse.decode(int8ar);
        await setLoading(false);

        if (decodedRes.longURL) {
          setSuccess(true);
          window.location.href = decodedRes.longURL;
        } else {
          switch (decodedRes.errors[0].code) {
            case ERROR_CODE:
              setErrorText(ERROR_TEXT);
              break;
            case LINK_EXPIRED_CODE:
              setLinkExpired(true);
              break;
            default:
              setErrorText("Something went wrong, please retry.");
              break;
          }
        }
      } catch (err) {
        setPageLoading(false);
        setRequestErrorText(GENERAL_ERROR_MSG);
        console.error(err);
      }
    });
  };

  /*********************************** Content based on link-expired ************************ */
  if (pageLoading || redirecting) {
    document.title = "Please Wait...";

    Content = (
      <ProgressIndicator
        variant="circular"
        size="large"
        labelPlacement="bottom"
        label="Please wait..."
        className="loadin-spinner"
      />
    );
  }

  if (requestErrorText && !linkExpired && !pageLoading) {
    document.title = `Error | ${getDocumentTitle()}`;
    Content = (
      <div className="link-expired">
        {requestErrorText && (
          <div className="expiration-msg">
            <IconProhibitionFull
              style={{
                display: "inline",
                marginRight: "12px",
                height: "35px",
                width: "35px",
              }}
            />
            {requestErrorText}
          </div>
        )}
      </div>
    );
  }

  if (linkExpired && !requestErrorText && !pageLoading) {
    document.title = `Invalid link | ${getDocumentTitle()}`;
    Content = (
      <div className="link-expired">
        {linkExpired && (
          <div className="expiration-msg">
            <IconProhibitionFull
              style={{
                display: "inline",
                marginRight: "12px",
                height: "35px",
                width: "35px",
              }}
            />
            {LINK_EXPIRED_TEXT}
          </div>
        )}
      </div>
    );
  }

  const onChangeHandler = (e) => {
    setErrorText("");
    if (e.target.value === "" || NUMBERS_REGEX.test(e.target.value))
      setText(e.target.value);
  };

  if (
    isProtected &&
    !linkExpired &&
    !requestErrorText &&
    !pageLoading &&
    !redirecting
  ) {
    document.title = `Enter OTP | ${getDocumentTitle()}`;

    Content = (
      <>
        <div>
          <Notification showStatusLabel={false} className="msg-mrg">
            Please enter OTP to access{" "}
            <strong>
              {shortUrl.length > 30
                ? `${shortUrl.substr(0, 27)}...${shortUrl.substr(
                  shortUrl.length - 5,
                  5
                )}`
                : shortUrl}
            </strong>
            .
          </Notification>
        </div>

        <form className="otp-ui" onSubmit={submitOTPHandler}>
          <Input
            inputClassName="letter-spacing-5"
            formField
            label="Enter OTP"
            textLength={{
              current: text.length,
              max: maxLength,
            }}
            hint={INPUT_HINT}
            value={text}
            autoFocus
            // secondaryAction={
            //   <Link
            //     color="primary"
            //     bold
            //     disabled
            //     className="small-text"
            //     underline="hover"
            //     children="Resend OTP"
            //   />
            // }
            required
            onChange={onChangeHandler}
            maxLength={maxLength}
            type={showOTP ? "text" : "password"}
            startEnhancer={<IconLockFull color="accent" />}
            endEnhancer={
              showOTP ? (
                <IconEyeEmpty
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowOTP(false)}
                />
              ) : (
                <IconEyeOff
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowOTP(true)}
                />
              )
            }
          />
          <div className="error-text">
            {errorText && (
              <Message
                matchColor
                className="err-msg"
                showStatusLabel={false}
                status="error"
                icon={<IconWarningTriangleFull />}
              >
                {errorText}
              </Message>
            )}
          </div>
          <div className="flex justify-evenly">
            {success ? (
              <Button
                variant="filled"
                color="success"
                loading
                label="Redirecting..."
              />
            ) : (
              <Button
                variant="filled"
                disabled={!(text.length === maxLength) || errorText}
                label="Submit OTP"
                loading={loading}
                type="submit"
              />
            )}
          </div>
        </form>
      </>
    );
  }

  return (
    <div className="App">
      {brand ? (
        <MyHeader brand={brand} />
      ) : (
        <div style={{ height: "13vh" }}></div>
      )}
      <div className="content">{Content}</div>
      {brand && <Footer brand={brand} />}
    </div>
  );
};

export default OTP;

export const FooterHelper = (brand) => {

  if (brand === "frontdoor")
    return {
      footerLinks: [
        {
          label: "About Us",
          href: "https://www.frontdoorhome.com/about/",
        },
        {
          label: "Privacy Policy",
          href: "https://www.frontdoorhome.com/privacy-policy/",
        },
        {
          label: "Terms of Use",
          href: "https://www.frontdoorhome.com/terms-of-use/",
        },
      ],
      contact: {
        label: "Contact",
        href: "https://www.frontdoorhome.com/contact/",
      },
    };

  if (brand === "ahs")
    return {
      footerLinks: [
        {
          label: "About Us",
          href: "https://www.ahs.com/about/",
        },
        {
          label: "Privacy Policy",
          href: "https://www.ahs.com/privacy-policy/",
        },
        {
          label: "Terms of Use",
          href: "https://www.ahs.com/terms-of-use/",
        },
      ],
      contact: {
        label: "888.682.1043",
        href: "tel:+18886821043",
      },
    };

  if (brand === "ahs-proconnect")
    return {
      footerLinks: [
        {
          label: "How It Works",
          href: "https://www.ahs.com/proconnect/how-it-works-usa/hw/999",
        },
        {
          label: "Privacy Policy",
          href: "https://www.ahs.com/proconnect/privacy-policy/",
        },
        {
          label: "Terms of Use",
          href: "https://www.ahs.com/proconnect/terms-of-use/",
        },
      ],
      rightLink: {
        label: "FAQ",
        href: "https://www.ahs.com/proconnect/faq/",
      },
    };

  if (brand === "hsa")
    return {
      footerLinks: [
        {
          label: "About Us",
          href: "https://www.onlinehsa.com/about-us/",
        },
        {
          label: "Privacy Policy",
          href: "https://www.onlinehsa.com/privacy-policy/",
        },
        {
          label: "FAQs",
          href: "https://www.onlinehsa.com/faqs/",
        },
      ],
      contact: {
        label: "Contact Us",
        href: "https://www.onlinehsa.com/contact/",
      },
    };

  if (brand === "hsa-proconnect")
    return {
      footerLinks: [
        {
          label: "About Us",
          href: "https://www.onlinehsa.com/about-us/",
        },
        {
          label: "Privacy Policy",
          href: "https://www.onlinehsa.com/privacy-policy/",
        },
        {
          label: "FAQs",
          href: "https://www.onlinehsa.com/faqs/",
        },
      ],
      contact: {
        label: "Contact Us",
        href: "https://www.onlinehsa.com/contact/",
      },
    };

  if (brand === "landmark")
    return {
      footerLinks: [
        {
          label: "Terms of Use",
          href: "https://www.landmarkhw.com/terms-of-use",
        },
        {
          label: "Privacy Policy",
          href: "https://www.landmarkhw.com/privacy-policy",
        },
        {
          label: "Resources",
          href: "https://www.landmarkhw.com/resources",
        },
      ],
      contact: {
        label: "Contact Us",
        href: "https://www.landmarkhw.com/contact-us",
      },
    };

  if (brand === "oneguard")
    return {
      footerLinks: [
        {
          label: "Why Oneguard?",
          href: "https://www.oneguardhomewarranty.com/why-oneguard/",
        },
        {
          label: "Terms of Service",
          href: "https://www.oneguardhomewarranty.com/terms-of-service/",
        },
        {
          label: "Privacy Policy",
          href: "https://www.oneguardhomewarranty.com/privacy-policy/",
        },
      ],
      contact: {
        label: "Contact Us",
        href: "https://www.oneguardhomewarranty.com/contact/",
      },
    };
};

export const getFooterLogoVariant = brand => {
  switch (brand){
    case 'ahs-proconnect': return "logo-type";
    case 'hsa-proconnect': return "logo-type";
    default: return "logo";
  }
}
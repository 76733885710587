import { getBrandByDomainName } from "./BrandHelper";

export const getDocumentTitle = () => {
  const brand = getBrandByDomainName();
  let brandInTitle;

  if (brand === "ahs") {
    brandInTitle = "American Home Shield | The Leading Home Warranty Company";
  }

  if (brand === 'ahs-proconnect') {
    brandInTitle = "American Home Shield ProConnect"
  }

  if (brand === 'hsa-proconnect') {
    brandInTitle = "HSA ProConnect"
  }

  if (brand === "hsa") {
    brandInTitle =
      "HSA - the Right Home Warranty for Homeowners, Real Estate Agents, and Service Providers";
  }

  if (brand === "frontdoor") {
    brandInTitle = "Frontdoor, Inc.";
  }

  return brandInTitle;
};

export const getSeconds = (value, unit) => {
  switch (unit) {
    case "day(s)":
      return value * 86400;
    case "hour(s)":
      return value * 3600;
    case "minute(s)":
      return value * 60;
    case "second(s)":
      return value;
    default:
      return value;
  }
};

import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AuthProvider } from "@ftdr/use-auth";
import { authConfig } from "./config/auth-config";
import OTP from "./components/OTP/OTP";
import NotFound from "./components/404/404";
import Home from "./components/Home";
import RouteCheck from "./components/CreateShortlink/RouteCheck";
import Callback from "./components/CallbackPage/Callback";
import Dashboard from "./components/Dashboard/Dashboard";

function App() {
  return (
    <div className="mx-auto" style={{maxWidth: '1440px'}}>
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>

        <Route path="/oauth2/callback" exact>
          <AuthProvider serviceConfigs={authConfig}>
            <Callback />
          </AuthProvider>
        </Route>

        <Route path="/lnk" exact>
          <AuthProvider serviceConfigs={authConfig}>
            <RouteCheck />
          </AuthProvider>
        </Route>

        <Route path="/dashboard/looker" exact>
          <Dashboard />
        </Route>

        <Route path="/page-not-found" exact>
          <NotFound />
        </Route>

        <Route path="/:code" exact>
          <OTP />
        </Route>

        <Redirect to="/page-not-found" />
      </Switch>
    </Router>
    </div>
  );
}

export default App;

export const getBrandNameByTenantID = (tenantID) => {
  switch (tenantID) {
    case 2:
      return "ahs";

    case 4:
      return "hsa";

    case 5:
      return "oneguard";

    case 6:
      return "landmark";

    case 7:
      return "frontdoor";

    case 8:
      return "ahs-proconnect";
  
    case 9:
      return "hsa-proconnect";
    
    case 10:
      return "frontdoor";
  
    default:
      return "frontdoor";
  }
};

export const getBrandByDomainName = () => {
  const domain = window.location.host;
  if (
    domain === "dev.ahs.co" ||
    domain === "test.ahs.co" ||
    domain === "training.ahs.co" ||
    domain === "staging.ahs.co" ||
    domain === "ahs.co"
  )
    return "ahs";

  else if (
    domain === "dev.hsa.co" ||
    domain === "test.hsa.co" ||
    domain === "training.hsa.co" ||
    domain === "staging.hsa.co" ||
    domain === "hsa.co"
  )
    return "hsa";

  else if (
    domain === "dev.ahspro.co" ||
    domain === "test.ahspro.co" ||
    domain === "training.ahspro.co" ||
    domain === "staging.ahspro.co" ||
    domain === "ahspro.co"
  )
    return "ahs-proconnect";

  else if (
    domain === "dev.hsapro.co" ||
    domain === "test.hsapro.co" ||
    domain === "training.hsapro.co" ||
    domain === "staging.hsapro.co" ||
    domain === "hsapro.co"
  )
    return "hsa-proconnect";

  else if (
    domain === "dev.ftdr.co" ||
    domain === "test.ftdr.co" ||
    domain === "training.ftdr.co" ||
    domain === "staging.ftdr.co" ||
    domain === "ftdr.co"
  )
    return "frontdoor";

  else {
    return "frontdoor";
  }
};

export const getBrandLink = () => {
  const brand = getBrandByDomainName();
  let brandLink;

  switch (brand){
    case "ahs":  brandLink = "https://www.ahs.com/";
                 break;
    
    case "hsa":  brandLink = "https://www.onlinehsa.com/";
                 break;
    
    case "ahs-proconnect":  brandLink = "https://www.ahs.com/proconnect";
                            break;
    
    case "hsa-proconnect":  brandLink = "https://www.onlinehsa.com/";
                            break;
    
    case "frontdoor":  brandLink = "https://www.frontdoorhome.com/";
                       break;
    
    default: brandLink = "https://www.frontdoorhome.com/";
             break;
  }

  return brandLink;
};

export const getShortlinkDomain = (tenantID) => {
  let shortlinkDomain = "ftdr.co";

  if (tenantID === 2) shortlinkDomain = "ahs.co";
  if (tenantID === 4) shortlinkDomain = "hsa.co";
  if (tenantID === 8) shortlinkDomain = "ahspro.co";
  if (tenantID === 9) shortlinkDomain = "hsapro.co";

  return shortlinkDomain;
};

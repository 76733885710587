import React, { useEffect } from "react";
import {
  Button,
  IconArrowRightCircled,
} from "@ftdr/blueprint-components-react";
import MyHeader from "../Header/Header";
import "./404.css";
import Footer from "../Footer/Footer";
import { getDocumentTitle } from "../../helpers/MetaData";
import { getBrandByDomainName, getBrandLink } from "../../helpers/BrandHelper";

const NotFound = () => {
  useEffect(() => {
    document.title = `404 Not Found | ${getDocumentTitle()}`;
  }, []);

  return (
    <>
      <MyHeader brand={getBrandByDomainName()} />
      <div className="content-404">
        <div className="not-found">
          <div className="left-section">404</div>
          <div className="right-section">
            <p className="p0">OOPS!</p>
            <p className="p1">
              <span className="span">404 - </span>Page Not Found.
            </p>
            <p className="p2">
              Make sure the address is correct and the page hasn't moved.
            </p>
          </div>
        </div>
        <div className="btn-div">
          <Button
            color="accent"
            width="auto"
            label={"Return Home"}
            href={getBrandLink()}
            endIcon={<IconArrowRightCircled />}
          />
        </div>
      </div>
      <Footer brand={getBrandByDomainName()} />
    </>
  );
};
export default NotFound;

export const authConfig = [
  {
    nickname: "ShortlinkAuth",
    authority:
      "https://login.microsoftonline.com/050e16e5-2cf7-4ca6-8d10-9a639dbeab71",
    client_id: "697ce09a-16a0-4ded-b463-c871e9d1af6a",
    debug: false,
    metadata_uri:
      "https://login.microsoftonline.com/050e16e5-2cf7-4ca6-8d10-9a639dbeab71/v2.0/.well-known/openid-configuration",
    post_logout_redirect_uri: window.location.origin + "/lnk",
    redirect_uri: window.location.origin + "/oauth2/callback",
    scope: "openid email profile",
  },
];

import React from "react";
import { Logo, Link } from "@ftdr/blueprint-components-react";
import "./Header.css";
import { useAuth } from "@ftdr/use-auth";
import { HeaderHelper } from "../../helpers/HeaderHelper";

const MyHeader = (props) => {
  const { logout } = useAuth();

  const headerBrand = HeaderHelper(props.brand);

  return (
    <div className={props.showLogoutButton ? "my-header-1" : "my-header"}>
        <Logo
          brand={props.brand}
          variant="logo"
          width={headerBrand.width ? headerBrand.width : "190"}
          href={headerBrand.href}
        />
        
      {props.showLogoutButton && (
        <Link
          className="logout-btn"
          onClick={logout}
          children="Logout"
          underline="always"
          bold
          color="primary"
        />
      )}
    </div>
  );
};

export default MyHeader;

import React, { useEffect, useState } from "react";
import {
  Button,
  Separator,
  IconArrowRightCircled,
} from "@ftdr/blueprint-components-react";

import MyHeader from "../Header/Header";
import Footer from "../Footer/Footer";
import { useAuth } from "@ftdr/use-auth";
import "./Login.css";
import { getBrandByDomainName } from "../../helpers/BrandHelper";
import { getDocumentTitle } from "../../helpers/MetaData";

const Login = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `Login | ${getDocumentTitle()}`;
  }, []);

  const content = (
    <div className="wrapper-div">
      <p className="title-login">Login Required</p>
      <Separator orientation="horizontal" gap="lg" />
      <div className="ms-login">
        <Button
          variant="filled"
          label="Continue with Frontdoor SSO"
          loading={loading}
          endIcon={<IconArrowRightCircled />}
          onClick={() => {
            setLoading(true);
            login("ShortlinkAuth");
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <MyHeader brand={getBrandByDomainName()} />
      <div className="card-1">{content}</div>
      <Footer brand={getBrandByDomainName()} />
    </>
  );
};

export default Login;

import React from "react";
import { Logo, Link, IconPhone } from "@ftdr/blueprint-components-react";
import { FooterHelper, getFooterLogoVariant } from "../../helpers/FooterHelper";
import "./Footer.css";

const Footer = (props) => {
  const footerBrand = FooterHelper(props.brand);
  const variant = getFooterLogoVariant(props.brand);

  return (
    <div className="footer">
      <div className="left-logo-footer">
          <Logo
            brand={props.brand}
            variant={variant}
            width={props.brand === "hsa" ? "75" : "130"}
          />
      </div>
      <div className="main-links">
        {footerBrand.footerLinks.map((link,i) => (
          <Link
            key={link.label}
            id={`footerLink_${i}`}
            color="primary"
            underline="always"
            bold
            children={link.label}
            external
            href={link.href}
          />
        ))}
      </div>
      <div className="right-link-footer">
        { 
          footerBrand.contact ?
            <Link
              id="contact"
              color="primary"
              bold
              children={footerBrand.contact.label}
              external
              startIcon={<IconPhone />}
              href={footerBrand.contact.href}
            />
          : <Link
              id="footerLink_right"
              color="primary"
              underline="always"
              bold
              children={footerBrand.rightLink.label}
              external
              href={footerBrand.rightLink.href}
            />
        }
      </div>
    </div>
  );
};

export default Footer;

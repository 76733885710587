import { useAuth } from "@ftdr/use-auth";
import React from "react";
import Login from "../Login/Login";
import CreateShortlink from "./CreateShortlink";

const RouteCheck = () => {
  const { oidcUser } = useAuth();
  const isLoggedIn = oidcUser && !oidcUser.expired;
  const content = isLoggedIn ? <CreateShortlink /> : <Login />;
  return content;
};

export default RouteCheck;

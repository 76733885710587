import React, { useEffect } from "react";
import { useAuth } from "@ftdr/use-auth";
import { Text } from "@ftdr/blueprint-components-react";

const Callback = () => {
  const { callback } = useAuth();

  useEffect(() => {
    callback();
  });

  return <Text>Logging in....</Text>;
};

export default Callback;

import { useEffect } from "react";
import { getBrandLink } from "../helpers/BrandHelper";
import { getDocumentTitle } from "../helpers/MetaData";

const Home = () => {
  useEffect(() => {
    document.title = `Home | ${getDocumentTitle()}`;
    window.location.href = getBrandLink();
  }, []);
  return null;
};
export default Home;

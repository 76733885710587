import React, { useEffect, useState } from "react";
import protobuf from "protobufjs";
import {
  Message,
  Button,
  Checkbox,
  Link,
  Notification,
  IconNavArrowDown,
  IconNavArrowUp,
  IconFormClearField,
  Select,
  IconInfoEmpty,
  Popover,
  PopoverContent,
  Text,
} from "@ftdr/blueprint-components-react";
import { useAuth } from "@ftdr/use-auth";
import {
  SHORTENURL_REQUEST_URL,
  LINK_EXISTS_CODE,
  SHORTURL_EXISTS,
  INVALID_URL_CODE,
  VANITY_REGEX,
  GENERAL_ERROR_MSG,
} from "../../Constants";
import MyHeader from "../Header/Header";
import Footer from "../Footer/Footer";
import "./CreateShortlink.css";
import { getSeconds } from "../../helpers/TimeHelper";
import {
  getBrandByDomainName,
  getShortlinkDomain,
} from "../../helpers/BrandHelper";
import { getTenantOptions } from "../../helpers/TenantOptionsHelper";
import { getDocumentTitle } from "../../helpers/MetaData";

const CreateShortlink = () => {
  const [longURL, setLongURL] = useState("");
  const [errorText, setErrorText] = useState("");
  const [vanityErrorText, setVanityErrorText] = useState("");
  const [shortURL, setShortURL] = useState("");
  const [shortURLCode, setShortURLCode] = useState("");
  const [OTP, setOTP] = useState("");
  const [ttlSecs, setTtlSecs] = useState(1);
  const [isProtected, setIsProtected] = useState(true);
  const [vanityUrl, setVanityUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAdvancedOptions, setShowAdvancedControls] = useState(false);
  const [preserveQueryParams, setPreserveQueryParams] = useState(false);

  const [accessToken, setAccessToken] = useState("");

  const timeUnitOptions = [
    { value: "day(s)" },
    { value: "hour(s)" },
    { value: "minute(s)" },
    { value: "second(s)" },
  ];
  const [timeUnit, setTimeUnit] = useState(timeUnitOptions[1]);

  const tenantOptions = getTenantOptions();

  const [tenant, setTenant] = useState(tenantOptions[0]);
  const tenantID = tenant.value;
  const { oidcUser } = useAuth();

  useEffect(() => {
    document.title = `Shorten URL | ${getDocumentTitle()}`;
    let isMounted = true;

    isMounted && setAccessToken(oidcUser.id_token);

    return () => (isMounted = false);
  }, [oidcUser.id_token]);

  //***********************************SHORTEN URL REQUEST************************** */
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    //**protobuf code here***
    let buffer;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-protobuf",
    };

    protobuf.load("protos/shortlink.proto").then(async function (root) {
      var ShortenURLRequest = root.lookupType("shortlinkpb.ShortenURLRequest");
      var ShortenURLResponse = root.lookupType(
        "shortlinkpb.ShortenURLResponse"
      );

      var payload = {
        longURL,
        protectURL: isProtected,
        ttlSecs: getSeconds(ttlSecs, timeUnit.value),
        tenantID: tenantID,
        slug: vanityUrl,
        preserveQSParams: preserveQueryParams,
      };

      var errMsg = ShortenURLRequest.verify(payload);

      if (errMsg) throw Error(errMsg);

      var message = ShortenURLRequest.create(payload);

      buffer = ShortenURLRequest.encode(message).finish();
      try {
        const res = await fetch(SHORTENURL_REQUEST_URL, {
          headers,
          method: "POST",
          body: buffer,
        });

        const resp = await res.arrayBuffer();
        const int8ar = new Uint8Array(resp);

        const decodedRes = await ShortenURLResponse.decode(int8ar);
        setLoading(false);
        if (decodedRes.errors[0]) {
          switch (decodedRes.errors[0].code) {
            case INVALID_URL_CODE:
              setErrorText("Invalid URL.");
              break;

            case LINK_EXISTS_CODE:
              setErrorText("Long URL already exists.");
              break;

            case SHORTURL_EXISTS:
              setVanityErrorText("Requested short URL keyword already exists.");
              break;

            default:
              setErrorText(decodedRes.errors[0].message);
              break;
          }
        }
        await setShortURL(decodedRes.shortURL);
        await setOTP(decodedRes.code);
        const code = decodedRes.shortURL;
        await setShortURLCode(code);
      } catch (err) {
        setLoading(false);
        setErrorText(GENERAL_ERROR_MSG);
        console.error(err);
      }
    });
  };

  const vanityChangeHandler = (e) => {
    setVanityErrorText("");
    if (e.target.value === "" || VANITY_REGEX.test(e.target.value))
      setVanityUrl(e.target.value);
  };

  useEffect(() => {
    return undefined;
  }, []);

  return (
    <>
      <MyHeader brand={getBrandByDomainName()} showLogoutButton />
      <div className="main-content">
        <form className="home" onSubmit={formSubmitHandler}>
          <label className="label-main" htmlFor="longUrlInput">
            * Enter Long URL
          </label>
          <div className={`input-style ${errorText ? " error" : ""}`}>
            <input
              className="input-url"
              type="url"
              id="longUrlInput"
              autoFocus
              value={longURL}
              required
              placeholder="Enter Long URL..."
              onChange={(e) => {
                setErrorText("");
                setShortURL("");
                setLongURL(e.target.value);
              }}
            />
            {longURL ? (
              <IconFormClearField
                color="primary"
                className="clear-btn"
                onClick={() => {
                  setErrorText("");
                  setVanityErrorText("");
                  setLongURL("");
                  setVanityUrl("");
                  setShortURL("");
                }}
              />
            ) : (
              ""
            )}
          </div>
          <div className="cntrls">
            <Checkbox
              label={"Protect URL"}
              name="protect-url"
              value={isProtected}
              checked={isProtected}
              onChange={(e) => setIsProtected(!isProtected)}
            />

            <Link
              startIcon={
                !showAdvancedOptions ? <IconNavArrowDown /> : <IconNavArrowUp />
              }
              className="advanced-link"
              underline="hover"
              children={
                !showAdvancedOptions
                  ? "Show Advanced Options"
                  : "Hide Advanced Options"
              }
              color="primary"
              onClick={(e) => setShowAdvancedControls(!showAdvancedOptions)}
            />
          </div>
          {showAdvancedOptions ? (
            <div className="controls">
              {getBrandByDomainName() === "frontdoor" && (
                <div className="tnt">
                  <label htmlFor="tnt-chk" className="label-main">
                    Select Tenant
                  </label>
                  <Select
                    id="tnt-chk"
                    placeholder="AHS"
                    options={tenantOptions}
                    selected={tenant}
                    onSelect={(option) => setTenant(option)}
                    className="checkbox-style"
                  />
                </div>
              )}

              <div className="vanity">
                <div className="left-div">
                  <label htmlFor="vanity-input" className="label-main">
                    Enter custom path
                  </label>
                  <Popover
                    triggerInteraction="hover-anchor-only"
                    content={(popoverContentProps) => (
                      <PopoverContent {...popoverContentProps}>
                        <Text
                          className="max-w-xs"
                          color="black"
                          variant="caption"
                        >
                          {`Example: If custom path is "home", then the shortlink would be https://${getShortlinkDomain(
                            tenantID
                          )}/home".`}
                        </Text>
                      </PopoverContent>
                    )}
                  >
                    <IconInfoEmpty size="20" color="primary" />
                  </Popover>
                </div>
                <input
                  id="vanity-input"
                  type="text"
                  className={`input-vanity ${vanityErrorText ? " error" : ""}`}
                  value={vanityUrl}
                  placeholder={`${getShortlinkDomain(tenantID)}/<custom-path>`}
                  onChange={vanityChangeHandler}
                />
              </div>
              <div className="ttl">
                <label htmlFor="ttl-secs" className="label-main">
                  Expires in
                </label>
                <div className="ttl-right">
                  <input
                    id="ttl-secs"
                    type="number"
                    min="0"
                    required
                    value={ttlSecs}
                    className="input-ttl"
                    onChange={(e) => setTtlSecs(parseInt(e.target.value))}
                  />
                  <Select
                    options={timeUnitOptions}
                    placeholder="hour(s)"
                    selected={timeUnit}
                    onSelect={(option) => setTimeUnit(option)}
                    className="ttl-chkbox"
                  />
                </div>
              </div>
              <div className="qsp">
                <Checkbox
                  label="Preserve query parameters (everything after '?')"
                  value={preserveQueryParams}
                  name="preserve-params"
                  checked={preserveQueryParams}
                  onChange={(e) => setPreserveQueryParams(!preserveQueryParams)}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {!errorText && !vanityErrorText ? (
            <Button
              type="submit"
              disabled={!accessToken}
              loading={loading}
              style={{ display: "block", margin: "20px auto" }}
              label={accessToken ? "Shorten URL" : "Please Wait ..."}
            />
          ) : (
            <Button
              disabled={errorText || vanityErrorText}
              style={{ display: "block", margin: "20px auto" }}
              label="Shorten URL"
            />
          )}
        </form>
        <div className="msg-mrg">
          {errorText || vanityErrorText ? (
            <Message status="error" matchColor showStatusLabel={true}>
              {errorText}
              {vanityErrorText}
            </Message>
          ) : (
            ""
          )}

          {longURL &&
            showAdvancedOptions &&
            !errorText &&
            !vanityErrorText &&
            !shortURL && (
              <Notification matchColor showStatusLabel={false}>
                Your shortlink will look like:{" "}
                <strong>
                  {`https://${getShortlinkDomain(tenantID)}/`}
                  {vanityUrl ? vanityUrl : "<some-code>"}
                </strong>{" "}
                and will{" "}
                {ttlSecs && ttlSecs > 0 ? (
                  <span>
                    expire in
                    <strong> {`${ttlSecs} ${timeUnit.value}.`}</strong>
                  </span>
                ) : (
                  <span>
                    <strong>not </strong>expire.
                  </span>
                )}
              </Notification>
            )}

          {shortURL && !errorText && !vanityErrorText && (
            <Notification status="success" showStatusLabel={false}>
              Your short url is:{" "}
              <a
                target="_blank"
                className="hlink"
                rel="noopener noreferrer"
                href={shortURLCode}
              >
                {shortURL}
              </a>
              {OTP && (
                <span>
                  {" "}
                  and the OTP is: <strong>{OTP}</strong>
                </span>
              )}
            </Notification>
          )}
        </div>
      </div>
      <Footer brand={getBrandByDomainName()} />
    </>
  );
};

export default CreateShortlink;

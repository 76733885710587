export const HeaderHelper = (brand) => {
  switch (brand){
    case "ahs":
      return {
        href: "https://www.ahs.com/",
      };

  case "ahs-proconnect":
    return {
      href: "https://www.ahs.com/proconnect/",
      width: "220"
    };

  case "hsa":
    return {
      href: "https://www.onlinehsa.com/",
      width: "140",
    };

  case 'hsa-proconnect':
    return {
      href: "https://www.onlinehsa.com/",
      width: "220",
    };

  case "landmark":
    return {
      href: "https://www.landmarkhw.com/",
    };

  case "oneguard":
    return {
      href: "https://www.oneguardhomewarranty.com/",
    };

  default:
    return {
      href: "https://frontdoorhome.com"
    }
  }
};
